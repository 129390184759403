import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero";
import CareersList from "../components/careers-list";

export default ({ data }) => (
  <Layout>
    <SEO
      title="Products"
      keywords={[
        "electrical",
        "Philippines",
        "design",
        "build",
        "system",
        "supply",
        "troubleshoot",
        "repair",
        "equipments",
        "test",
        "manage",
        "bms"
      ]}
    />
    <div className="gradient-half-blue-v1">
      <Hero
        desktopWide={data.bgDesktopWide.childImageSharp.fluid.src}
        desktop={data.bgDesktop.childImageSharp.fluid.src}
        tablet={data.bgTablet.childImageSharp.fluid.src}
        mobile={data.bgMobile.childImageSharp.fluid.src}
        mobileXS={data.bgMobileXS.childImageSharp.fluid.src}
      >
        <h1 className="text-white">Careers</h1>
        <p className="text-white lead">Be part of growing company.</p>
      </Hero>
    </div>

    <div className="container space-2 space-3--lg">
      <div className="mb-7">
        <div className="text-center mb-4">
          <h3 className="h5">Open Positions</h3>
        </div>
        <CareersList />
      </div>
    </div>
  </Layout>
);

export const query = graphql`
  query {
    bgDesktopWide: file(relativePath: { eq: "static/happy.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgDesktop: file(relativePath: { eq: "static/happy.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgTablet: file(relativePath: { eq: "static/happy.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 992, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgMobile: file(relativePath: { eq: "static/happy.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgMobileXS: file(relativePath: { eq: "static/happy.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 576, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
