import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";

class CareersList extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allKdccmsCareers;

    return (
      <ul className="list-unstyled mb-0">
        {posts &&
          posts.map(({ node: post }) => (
            <li className="my-3" key={post.id}>
              <Link
                className="d-block text-dark card-frame rounded p-4"
                to={`/careers/${post.slug}`}
              >
                <span className="row justify-content-sm-between align-items-sm-center">
                  <span className="col-sm-6 mb-2 mb-sm-0">
                    {post.title}
                    <small className="d-block text-muted">
                      {post.location}
                    </small>
                  </span>
                  <span className="col-sm-6 text-primary text-sm-right">
                    {post.employeeType}
                  </span>
                </span>
              </Link>
            </li>
          ))}
      </ul>
    );
  }
}

CareersList.propTypes = {
  data: PropTypes.shape({
    allKdccmsCareers: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export default () => (
  <StaticQuery
    query={graphql`
      query CareersListQuery {
        allKdccmsCareers(sort: { order: DESC, fields: [publishDate] }) {
          edges {
            node {
              id
              slug
              title
              employeeType
              location
            }
          }
        }
      }
    `}
    render={(data, count) => <CareersList data={data} count={count} />}
  />
);
